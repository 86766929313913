'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.posStatus', [])
.factory('PosStatusService', [
    function () {
        var offlineShiftInQueue = false;

        /* Determines if user is actually logged in or using offline mode.
           If it is in offline mode and /me returns an error ie. the user wasn't logged in
           before going offline, then we prompt the user to log in. */
        var loggedIn = false;

        var offline = false;
        var offlineTransactionCount = 0;
        var iosBluetoothOnline = true;

        var shiftStarted = false;
        var quickChargeEnabled = false;

        var lucovaUsersLastUpdated = undefined;
        var currentTimestamp = undefined;

        var lastTransaction = undefined;
        var completedTransaction = undefined;

        var mobileOrderEnabled = false;
        var freeTrialEnabled = true;

        var isTutorial = false;
        var tutorialPage = 1;

        var isExpired = false;

        var hierarchy = {};

        var seenPushMessagesArr = [];

        var updateCurrentTimeStamp = function () {
            setInterval(function () {
               currentTimestamp = Date.now();
            }, 15000); // update every 15 seconds
        };
        updateCurrentTimeStamp();

        var setLoggedIn = function (value) {
            loggedIn = value;
        };

        var setOfflineShiftInQueue = function (value) {
            offlineShiftInQueue = value;
        };

        var setOffline = function (value) {
            offline = value;
        };

        var setIosBluetoothOnline = function (value) {
            iosBluetoothOnline = value;
        };

        var lowConnectivity = false;

        return {
            offlineShiftInQueue: function () {
                return offlineShiftInQueue;
            },
            setOfflineShiftInQueue: setOfflineShiftInQueue,
            userIsLoggedIn: function () {
                return loggedIn;
            },
            setLoggedIn: setLoggedIn,
            isOffline: function () {
                return offline;
            },
            setOffline: setOffline,

            iosBluetoothOnline: function () {
                return iosBluetoothOnline;
            },
            setIosBluetoothOnline: setIosBluetoothOnline,
            isQuickChargeEnabled: function () {
                return quickChargeEnabled;
            },
            setQuickChargeEnabled: function (value) {
                quickChargeEnabled = value;
            },
            isTutorial: function () {
                return isTutorial;
            },
            setIsTutorial: function (value) {
                isTutorial = value;
            },
            tutorialPage: function () {
                return tutorialPage;
            },
            setTutorialPage: function (value) {
                tutorialPage = value;
            },
            showTutorial: function () {
                isTutorial = true;
                tutorialPage = 1;
            },
            hideTutorial: function () {
                isTutorial = false;
                tutorialPage = 1;
            },
            nextTutorialPage: function () {
                tutorialPage++;
            },
            isExpired: function () {
                return isExpired;
            },
            showExpired: function () {
                isExpired = true;
            },
            isMobileOrderEnabled: function () {
                return mobileOrderEnabled;
            },
            setMobileOrderEnabled: function (value) {
                mobileOrderEnabled = value;
            },
            mobileOrderEnabled: mobileOrderEnabled,
            isFreeTrialEnabled: function () {
                return freeTrialEnabled;
            },
            setFreeTrialEnabled: function (value) {
                freeTrialEnabled = value;
            },
            freeTrialEnabled: freeTrialEnabled,
            hierarchy: hierarchy,
            quickChargeEnabled: quickChargeEnabled,
            shiftStarted: shiftStarted,
            lucovaUsersLastUpdated: lucovaUsersLastUpdated,
            lastTransaction: lastTransaction,
            completedTransaction: completedTransaction,
            currentTimestamp: currentTimestamp,
            isMessageSeen: function (messageId) {
                return !!_.find(seenPushMessagesArr, function (message) {
                    return message.messageId == messageId;
                });
            },
            addSeenMessage: function (messageId) {
                // everytime we add to our array, lets ensure we also clean it
                // up a bit. Delete all messages older than 15 seconds
                seenPushMessagesArr = _.filter(seenPushMessagesArr, function (message) {
                    return message.timestamp > Date.now() - 15000;
                });

                seenPushMessagesArr.push({timestamp: Date.now(), messageId: messageId});
            },
            setLowConnectivity: function (value) {
                lowConnectivity = value;
            },
            lowConnectivity: function () {
                return lowConnectivity;
            },
            setOfflineTransactionCount: function (count) {
                offlineTransactionCount = count;
            },
            getOfflineTransactionCount: function () {
                return offlineTransactionCount;
            }
        };
    }]);
