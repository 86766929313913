'use strict';

export function platformService (angular) {

    angular.module('NownPlatform', []).factory('Platform', function () {
        var isElectron = function () {
            if (typeof window !== 'undefined') {
                // Running from browser
                if (window.process && window.process.type) {
                    // Running from electron
                    return true;
                }
            }
            return false;
        };

        var isIosWebkit = function () {
            if (window.webkit && window.webkit.messageHandlers) {
                // Running from WkWebview
                return true;
            }
            return false;
        };

        var isTizen = function () {
            try {
                return navigator.userAgent.indexOf('Tizen') > 0;
            } catch (e) {
                return false;
            }
        };

        return {
            isElectron: isElectron,
            isIosWebkit: isIosWebkit,
            isTizen: isTizen,
        };
    });
}
