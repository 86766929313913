'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.lookup', [ngResource])
    .factory('Lookup', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/lookup', {}, {
            timeZones: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/timeZones', isArray: true},
            mealPlanCycle: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/mealPlanCycle', isArray: true},
            serverAttributes: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/serverAttributes', isArray: false},
            roleTypes: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/roleTypes', isArray: true},
            companyLocations: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/companyLocations', isArray: true},
            locationPOSStations: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/locationPOSStations', isArray: true},
            locationServicePeriods: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/locationServicePeriods', isArray: true},
            companies: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/companies', isArray: true},
            mobileApps: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/mobileApps', isArray: true},
            mealPeriods: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/mealPeriods', isArray: true},
            company: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/company'},
            companyHierarchy: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/companyHierarchy', isArray: true},
            allMealPlans: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/allMealPlans', isArray: true},
            companyMealPlans: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/companyMealPlans', isArray: true},
            rolePermissions: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/rolePermissions', isArray: false},
            rolePermissionsByCompany: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/rolePermissionsByCompany', isArray: false},
            pingPong: {method: 'POST', url: EnvConfig.host + '/freshideas/web/pingPong', isArray: false},
            statusCheck: {method: 'POST', url: EnvConfig.host + '/freshideas/web/status', isArray: false},
            locationsByCompany: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/locationsByCompany', isArray: true},
            companyAttributeSecure: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/companyAttributeSecure', isArray: false},
            locationsByOrganization: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/locationsByOrganization', isArray: true},
            taxRatesByOrganization: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/taxRatesByOrganization', isArray: true},
            staticMap: {method: 'GET', url: EnvConfig.host + '/freshideas/web/lookup/staticMap'}
        });
    }]);
